<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑消费者信息' : '新增消费者信息'" append-to-body width="640px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form">
      <el-form-item prop="name" label="姓名">
        <el-input v-model="form.name" :maxlength="25" style="width: 180px;" />
      </el-form-item>
      <el-form-item prop="phone" label="手机号码">
        <el-input v-model="form.phone" :maxlength="11" style="width: 180px;" />
      </el-form-item>

      <div class="h c">
        <el-form-item label="联系地址" prop="provinceCode">
          <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.cityCode = null; form.districtCode = null;" />
        </el-form-item>
        <el-form-item label-width="12px" prop="cityCode" style="width: 180px;">
          <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
          <el-input disabled placeholder="请先选择省份" v-else />
        </el-form-item>
        <el-form-item label-width="12px" prop="districtCode" style="width: 180px;">
          <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
          <el-input disabled placeholder="请先选择城市" v-else />
        </el-form-item>
      </div>      
      <el-form-item prop="address">
        <el-input v-model="form.address" :maxlength="100" class="flex" placeholder="请输入详细地址" />
      </el-form-item>
      <div class="h c">
        <el-form-item prop="isMain" label="长期客户">
          <div class="h c">
            <el-switch v-model="form.isMain" ></el-switch>
            <span class="fc-g">&emsp;* 为长期客户时可在零售订单中选取</span>
          </div>
        </el-form-item>
      </div>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/customer";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      rules: {
        name: [{ required: true, message: "请填写消费者姓名" }],
        phone: [
          { required: true, message: "请填写消费者人手机号码" },
          { pattern: /^\d{11}$/gi, message: "手机号码的格式不正确" }
        ]
      }
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then(res => {
              this.$notify({
                title: `保存消费者信息成功`,
                type: "success",
                duration: 2500
              });
              this.$parent.init();
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form) {
      this.form = form || {
        name: "",
        phone: "",
        provinceCode: null,
        provinceName: "",
        cityCode: null,
        cityName: "",
        districtCode: null,
        districtName: "",
        address: ""
      };
      this.visible = true;
    }
  }
};
</script>